import styled from 'styled-components'
import {
  width350,
  width420,
  width480,
  width600,
  width700,
  width990,
  width1400
} from '../../responsive'

export const PageBenefits = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 10%;
  ${width990({ padding: '8% 10%' })};
`
export const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const Title = styled.h1`
  color: #f26522;
  text-align: center;
`

// POLIGONOS
export const Polygon = styled.div`
  padding-top: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
`

export const Pentagons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Trapezes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const PentagonLeft = styled.div`
  background-color: ${props => props.color};
  max-width: 260px;
  max-height: 260px;
  width: 30vw;
  height: 30vw;
  clip-path: polygon(50% 15%, 100% 15%, 100% 100%, 25% 100%, 0 65%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  svg {
    width: 40px;
    height: 40px;
    margin-left: 35px;
    margin-top: 65px;
    ${width990({ width: '35px', height: '35px' })};
    ${width600({
      width: '20px',
      height: '20px',
      marginLeft: '20px',
      marginTop: '30px'
    })};
  }
`

export const PentagonRight = styled.div`
  background-color: ${props => props.color};
  max-width: 260px;
  max-height: 260px;
  width: 30vw;
  height: 30vw;
  clip-path: polygon(50% 15%, 0 15%, 0 100%, 75% 100%, 100% 65%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  svg {
    width: 50px;
    height: 50px;
    margin-top: 65px;
    margin-right: 35px;
    ${width990({ width: '40px', height: '40px' })};
    ${width600({
      width: '20px',
      height: '20px',
      marginRight: '20px',
      marginTop: '30px'
    })};
  }
`

export const TrapezeLeft = styled.div`
  background-color: ${props => props.color};
  max-width: 195px;
  max-height: 195px;
  width: 22.4vw;
  height: 22.4vw;
  clip-path: polygon(100% 0, 0 0, 51% 75%, 100% 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  svg {
    width: 35px;
    height: 35px;
    margin-left: 70px;
    margin-bottom: 50px;
    ${width990({ width: '30px', height: '30px' })}
    ${width600({
      width: '15px',
      height: '15px',
      marginBottom: '30px',
      marginLeft: '30px'
    })}
  }
`

export const TrapezeRight = styled.div`
  background-color: ${props => props.color};
  max-width: 195px;
  max-height: 195px;
  width: 22.4vw;
  height: 22.4vw;
  clip-path: polygon(100% 0, 0 0, 0 75%, 50% 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  svg {
    width: 35px;
    height: 35px;
    margin-bottom: 50px;
    margin-right: 50px;
    ${width990({ width: '30px', height: '30px' })}
    ${width600({
      width: '15px',
      height: '15px',
      marginBottom: '30px',
      marginRight: '20px'
    })}
  }
`

export const Triangle = styled.div`
  background-color: ${props => props.color};
  max-width: 193px;
  max-height: 193px;
  width: 23vw;
  height: 23vw;
  margin-top: -49px;
  clip-path: polygon(0 0, 50% 70%, 100% 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  ${width700({ marginTop: '-42px' })}
  ${width600({ marginTop: '-33px' })}
  ${width480({ marginTop: '-28px' })}
  ${width420({ marginTop: '-25px' })}
  ${width350({ marginTop: '-19px' })}

  svg {
    width: 35px;
    height: 35px;
    margin-bottom: 80px;
    ${width990({ width: '30px', height: '30px' })}
    ${width600({ width: '13px', height: '13px', marginBottom: '45px' })}
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  ${width1400({ display: 'none !important' })}
`

export const Line = styled.span`
  background-color: black;
  width: 200px;
  height: 1px;
  z-index: 2;
  position: absolute;
  margin-bottom: 65px;

  margin-right: ${props => props.direction === 'pentagonLeft' && '500px'};
  margin-right: ${props => props.direction === 'trapezeLeft' && '350px'};
  margin-bottom: ${props => props.direction === 'triangle' && '250px'};
  margin-left: ${props => props.direction === 'triangle' && '200px'};
`

export const Desc = styled.p`
  position: absolute;
  width: 280px;
  color: #353535;
  margin-bottom: 65px;

  /* pentagonLeft */
  right: ${props => props.polygon === 'pentagonLeft' && '390px;'};
  text-align: ${props => props.polygon === 'pentagonLeft' && 'right;'};

  /* pentagonRight */
  left: ${props => props.polygon === 'pentagonRight' && '140px;'};

  /* trapezeLeft */
  right: ${props => props.polygon === 'trapezeLeft' && '320px'};
  text-align: ${props => props.polygon === 'trapezeLeft' && 'right'};

  /* trapezeRight */
  left: ${props => props.polygon === 'trapezeRight' && '140px'};

  /* triangle */
  left: ${props => props.polygon === 'triangle' && '240px'};
  bottom: ${props => props.polygon === 'triangle' && '10px'};
`

export const ResponsiveDesc = styled.div`
  display: none;
  ${width1400({ display: 'block' })}
`

export const DesktopDesc = styled.div`
  display: flex;
  flex-direction: column;
  ${width700({ display: 'none' })}
`

export const CardMobile = styled.div`
  display: none;
  margin-top: 20px;
  width: 100%;
  padding: 0 10%;
  justify-content: center;
  ${width1400({ display: 'flex' })}
`

export const DescMobile = styled.p`
  color: #353535;
  text-align: center;
  font-size: calc(0.22vw + 14.28px);
`

export const Arrows = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0px 5%;
  margin-top: 25px;

  svg {
    opacity: 0.6;
    transform: scale(1.5);
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

`