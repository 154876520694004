import styled from 'styled-components'
// import { width990 } from '../../responsive'

export const Center = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
`

export const Arrow = styled.div`
  padding-right: 50px;

  svg {
    color: #000000;
    cursor: pointer;
  }
`

export const Title = styled.h3`
  margin-top: 50px;
  font-weight: bold;
  max-width: 80%;
`

export const Logo = styled.div`
  width: 40%;
  max-width: 200px;
  min-width: 120px;
  color: #ffffff;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const CardInput = styled.div`
  width: 90%;
  max-width: 500px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 5px 5px;
    object-fit: cover;
  }

  input {
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 15px;
    object-fit: cover;
  }

  button {
    padding: 13px 0;
    background: #f26522;
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: 100%;
    border: 0px solid transparent;
    border-radius: 5px;
    margin-top: 20px;
  }
`

export const ListOrder = styled.div`
  width: 90%;
  max-width: 500px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
  gap: 10px;

  :last-of-type {
    border-bottom: 1px solid transparent;
  }

  span {
    width: 25%;
    margin: 0;
  }

  button {
    padding: 8px 20px;
    background: #f26522;
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: max-content;
    border: 0px solid transparent;
    border-radius: 5px;
  }
`

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid gray;
  padding-bottom: 10px;
  gap: 10px;
  margin-bottom: 10px;

  span {
    width: 25%;
    margin: 0;
  }

  button {
    padding: 8px 20px;
    background: #f26522;
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: max-content;
    border: 0px solid transparent;
    border-radius: 5px;
    opacity: 0;
  }
`