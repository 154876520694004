import { PageWhatIs, Logo, Description } from './styles'
import img from '../../assets/images/logo_hash4pay_2.png'

export const WhatIs = () => {
  return (
    <>
      <PageWhatIs id="what-is">
        <Logo>
          <img src={img} alt="Logo Hashlar" />
        </Logo>

        <Description>
          <p>
            A <b>Hash4</b> é o primeiro gateway de pagamentos de criptoativos dedicado ao
            seu negócio!
          </p>
          <p>
            Nossa solução pode ser integrada no atacado, varejo, serviços, alimentação ou e-
            commerce. Não importa ou volume de transação ou tamanho da sua empresa, a
            Hash4 atende.
          </p>
          <p>
            Você vende, a gente transaciona, e você recebe forma imediata direto em R$ ou
            qualquer moeda, de forma segura e sem burocracia!
          </p>
        </Description>
      </PageWhatIs>
    </>
  )
}