import styled from 'styled-components'
import { width990 } from '../../responsive'

export const PageWhatIs = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 5% 10%;
  ${width990({ flexDirection: 'column', padding: '8% 10%' })}
`

export const Logo = styled.div`
  width: 50%;
  ${width990({ width: '100%', textAlign: 'center' })}

  img {
    width: 80%;
    max-width: 600px;
    min-width: 240px;
  }

  h3 {
    font-style: italic;
    color: #f26522;
  }
`

export const Description = styled.div`
  width: 50%;
  padding-right: 10%;
  padding-left: 5%;
  margin-top: 15%;
  ${width990({ width: '100%', margin: '45px 0', paddingRight: '0' })}

  p {
    font-size: calc(0.22vw + 14.28px);
    margin-bottom: 15px;
    color: #353535;
  }
`
