import styled from 'styled-components'

import { width1400, width700, width768, width990 } from '../../responsive'

export const PageSolution = styled.div`
  background-color: #f26522;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 300px;
  padding: 5% 10%;
  ${width1400({ flexDirection: 'column', gap: '30px', padding: '8% 10%' })};

  h1 {
    color: #ffffff;
    text-align: left;
    max-width: 280px;
    ${width1400({ maxWidth: '100%' })};
    ${width768({ textAlign: 'center', maxWidth: '100%' })}
  }
`

export const Text = styled.p`
  padding: 25px 0;
  margin: 20px 0;
  color: #fff;
`

export const ImageResponsive = styled.div`
  display: none;
  ${width990({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  })}

  img {
    width: 100%;
    ${width1400({ maxWidth: '400px' })}
  }
`

export const DesktopResponsive = styled.div`
  display: block;
  ${width768({ display: 'none' })}
`

export const InvertResponsive = styled.div`
  display: none;
  ${width768({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    tranform: 'rotate()'
  })}
`

export const Circles = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${width1400({ width: '100%', marginTop: '40px', marginLeft: '95px' })};
  ${width990({ display: 'none' })}
`

export const CircleCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 23vw;
  height: 23vw;
  margin-top: 125px;
  max-width: 200px;
  max-height: 200px;
  min-width: 150px;
  min-height: 150px;
  z-index: 5;
  margin: 50px;
  background-color: #ffffff;

  img {
    width: 18vw;
    height: 18vw;
    max-width: 50%;
    max-height: 50%;
    min-width: 75%;
    min-height: 75%;
  }

  ${width768({
    width: '5rem',
    height: '5rem',
    maxHeight: '50px',
    minWidth: '100px',
    minHeight: '100px',
    marginTop: '15rem'
  })}

  ${width768({ trasform: 'rotate()' })}
`

export const UserLine = styled.div`
  width: 4px;
  height: 450px;
  background-color: #ffffff;
  z-index: 2;
  position: absolute;

  transform: ${props => props.direction === '270' && 'rotate(270deg)'};
  margin-right: ${props => props.direction === '270' && '400px'};
  margin-bottom: ${props => props.direction === '270' && '10px'};

  ${width700({
    height: '230px',
    transform: props => props.direction === '270' && 'rotate(0deg)',
    marginRight: props => props.direction === '270' && '0',
    marginBottom: props => props.direction === '270' && '150px'
  })}
`

export const Line = styled.div`
  width: 4px;
  height: 130px;
  background-color: #ffffff;
  z-index: 2;
  position: absolute;

  /* Line 0 */
  transform: ${props => props.direction === '0' && 'rotate(0deg)'};
  margin-bottom: ${props => props.direction === '0' && '250px'};

  /* Line 60 */
  transform: ${props => props.direction === '60' && 'rotate(60deg)'};
  margin-left: ${props => props.direction === '60' && '250px'};
  margin-bottom: ${props => props.direction === '60' && '160px'};

  /* Line 120 */
  transform: ${props => props.direction === '120' && 'rotate(120deg)'};
  margin-left: ${props => props.direction === '120' && '225px'};
  margin-top: ${props => props.direction === '120' && '130px'};

  /* Line 180 */
  transform: ${props => props.direction === '180' && 'rotate(180deg)'};
  margin-top: ${props => props.direction === '180' && '250px'};

  /* Line 240 */
  transform: ${props => props.direction === '240' && 'rotate(240deg)'};
  margin-right: ${props => props.direction === '240' && '225px'};
  margin-top: ${props => props.direction === '240' && '125px'};

  /* Line 270 */
  transform: ${props => props.direction === '270' && 'rotate(270deg)'};
  margin-right: ${props => props.direction === '270' && '400px'};
  margin-bottom: ${props => props.direction === '270' && '10px'};

  /* Line 300 */
  transform: ${props => props.direction === '300' && 'rotate(300deg)'};
  margin-right: ${props => props.direction === '300' && '250px'};
  margin-bottom: ${props => props.direction === '300' && '160px'};

  @media (max-width: 700px) {
    height: 100px;

    /* line 0 */
    transform: ${props => props.direction === '0' && 'rotate(100deg)'};
    margin-bottom: ${props => props.direction === '0' && '0px'};
    margin-left: ${props => props.direction === '0' && '150px'};
    margin-top: ${props => props.direction === '0' && '50px'};

    /* line 120 */
    transform: ${props => props.direction === '120' && 'rotate(210deg)'};
    margin-left: ${props => props.direction === '120' && '0px'};
    margin-top: ${props => props.direction === '120' && '315px'};
    margin-right: ${props => props.direction === '120' && '50px'};
    margin-bottom: ${props => props.direction === '120' && '80px'};

    margin-top: ${props => props.direction === '180' && '100px'};

    /* line 60 */
    transform: ${props => props.direction === '60' && 'rotate(50deg)'};
    margin-left: ${props => props.direction === '60' && '135px'};
    margin-bottom: ${props => props.direction === '60' && '55px'};

    /* Line 300 */
    transform: ${props => props.direction === '300' && 'rotate(150deg)'};
    margin-right: ${props => props.direction === '300' && '0px'};
    margin-bottom: ${props => props.direction === '300' && '85px'};
    margin-left: ${props => props.direction === '300' && '45px'};
    margin-top: ${props => props.direction === '300' && '305px'};
  }
`

export const BigCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${props => (props.client ? 'width: 170px;' : 'width: 150px;')}
  ${props => (props.client ? 'height: 170px;' : 'height: 150px;')}

  background-color: #ffffff;
  z-index: 20;

  position: ${props => props.circle === '2' && 'absolute'};
  margin-left: ${props => props.circle === '2' && '415px'};
  margin-bottom: ${props => props.circle === '2' && '220px'};

  position: ${props => props.circle === '3' && 'absolute'};
  margin-left: ${props => props.circle === '3' && '415px'};
  margin-top: ${props => props.circle === '3' && '220px'};

  position: ${props => props.circle === '5' && 'absolute'};
  margin-right: ${props => props.circle === '5' && '415px'};
  margin-top: ${props => props.circle === '5' && '220px'};

  position: ${props => props.circle === '6' && 'absolute'};
  margin-right: ${props => props.circle === '6' && '415px'};
  margin-bottom: ${props => props.circle === '6' && '220px'};

  position: ${props => props.circle === '7' && 'absolute'};
  margin-right: ${props => props.circle === '7' && '900px'};
  margin-bottom: ${props => props.circle === '7' && '30px'};

  ${props =>
    props.client
      ? width700({
          width: '25vw',
          height: '25vw'
        })
      : width700({
          width: '20vw',
          height: '20vw'
        })}

  ${width990({
    marginBottom: props => props.circle === '1' && '-15px'
  })}

${width700({
    psition: props => props.circle === '1' && 'absolute',
    marginTop: props => props.circle === '1' && '60px',
    marginLeft: props => props.circle === '1' && '280px'
  })}

  ${width990({
    marginLeft: props => props.circle === '2' && '345px',
    marginBottom: props => props.circle === '2' && '185px'
  })}

${width700({
    marginLeft: props => props.circle === '2' && '120px',
    marginTop: props => props.circle === '2' && '330px',
    marginBottom: props => props.circle === '2' && '0px'
  })}

  ${width990({
    marginLeft: props => props.circle === '3' && '345px',
    marginTop: props => props.circle === '3' && '185px'
  })}

${width700({
    marginLeft: props => props.circle === '3' && '-105px',
    marginTop: props => props.circle === '3' && '330px',
    marginBottom: props => props.circle === '3' && '0px'
  })}

  ${width990({
    marginRight: props => props.circle === '5' && '345px',
    marginTop: props => props.circle === '5' && '185px'
  })}

  ${width990({
    marginBottom: props => props.circle === '4' && '-405px'
  })}

${width700({
    psition: props => props.circle === '4' && 'absolute'
  })}

  ${width990({
    marginRight: props => props.circle === '6' && '345px',
    marginBottom: props => props.circle === '6' && '185px'
  })}

${width700({
    marginLeft: props => props.circle === '6' && '195px',
    marginBottom: props => props.circle === '6' && '100px',
    marginRight: '0px'
  })}
`

export const CardInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  ${width1400({
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  })};
  ${width768({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  })}
`

export const Description = styled.p`
  width: 60%;
  max-width: 400px;
  margin-top: 25px;
  color: #ffffff;
  font-weight: 400;
  color: #ffffff;
  padding: 20px 0;

  ${width1400({ maxWidth: '100%', padding: '0', marginTop: '0' })};
  ${width768({ width: '100%', textAlign: 'center', marginTop: '15px' })};
`

export const CircleIcon = styled.div`
  border-radius: 50%;
  ${props => (props.client ? 'width: 120px;' : 'width: 100px;')}
  ${props => (props.client ? 'height: 120px;' : 'height: 100px;')}
  
  
  background-color: rgba(101, 216, 29, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;

  ${props =>
    props.client
      ? width700({
          width: '17vw',
          height: '17vw'
        })
      : width700({
          width: '12vw',
          height: '12vw'
        })}

  img {
    width: ${props => props.type === 'tron' && ''};
  }

  @media (max-width: 700px) {
    img {
      ${props => (props.type === 1 ? 'width: 70%;' : 'width: 45%')}
    }
  }
`
