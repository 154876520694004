import styled from 'styled-components'
import { width1100, width600, width768, width990 } from '../../responsive'

export const ConstContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  ${width768({ display: 'none' })};
`

export const PageHowWorks = styled.div`
  background-color: #f26522;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  ${width1100({ flexDirection: 'column', padding: '8% 10%;' })};
  overflow: hidden;

  h1 {
    color: #ffffff;
    text-align: left;
    max-width: 280px;
    ${width600({ textAlign: 'center', maxWidth: '100%' })}
  }
`

export const CardInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  ${width1100({
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  })};
  ${width600({ flexDirection: 'column' })};
`

export const Description = styled.p`
  margin-top: 25px;
  color: #ffffff;
  font-weight: 400;
  color: #ffffff;
  padding: 20px 0;

  ${width1100({
    maxWidth: '100%',
    padding: '0',
    marginTop: '0',
    marginLeft: '40px'
  })};
  ${width600({ width: '100%', textAlign: 'center', marginLeft: '0' })};
`

export const ImageResponsive = styled.div`
  display: none;
  ${width768({
    width: '100%',
    padding: '0 8%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px'
  })};

  img {
    width: 100%;
    max-width: 375px;
  }
`

export const Circles = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${width1100({ width: '100%', marginTop: '40px' })};
  ${width768({ display: 'none' })};
`

export const CircleCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 23vw;
  height: 23vw;
  max-width: 200px;
  max-height: 200px;
  min-width: 150px;
  min-height: 150px;
  z-index: 5;
  margin: 50px;
  background-color: #ffffff;

  img {
    width: 18vw;
    height: 18vw;
    max-width: 150px;
    max-height: 150px;
    min-width: 120px;
    min-height: 120px;
  }
`

export const Line = styled.div`
  width: 4px;
  height: 130px;
  background-color: #ffffff;
  z-index: 2;
  position: absolute;

  /* Line 0 */
  transform: ${props => props.direction === '0' && 'rotate(0deg)'};
  margin-bottom: ${props => props.direction === '0' && '250px'};

  /* Line 60 */
  transform: ${props => props.direction === '60' && 'rotate(60deg)'};
  margin-left: ${props => props.direction === '60' && '250px'};
  margin-bottom: ${props => props.direction === '60' && '160px'};

  /* Line 120 */
  transform: ${props => props.direction === '120' && 'rotate(120deg)'};
  margin-left: ${props => props.direction === '120' && '225px'};
  margin-top: ${props => props.direction === '120' && '130px'};

  /* Line 180 */
  transform: ${props => props.direction === '180' && 'rotate(180deg)'};
  margin-top: ${props => props.direction === '180' && '250px'};

  /* Line 240 */
  transform: ${props => props.direction === '240' && 'rotate(240deg)'};
  margin-right: ${props => props.direction === '240' && '225px'};
  margin-top: ${props => props.direction === '240' && '125px'};

  /* Line 300 */
  transform: ${props => props.direction === '300' && 'rotate(300deg)'};
  margin-right: ${props => props.direction === '300' && '250px'};
  margin-bottom: ${props => props.direction === '300' && '160px'};
`

export const BigCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #ffffff;
  z-index: 20;

  position: ${props => props.circle === '2' && 'absolute'};
  margin-left: ${props => props.circle === '2' && '415px'};
  margin-bottom: ${props => props.circle === '2' && '220px'};

  position: ${props => props.circle === '3' && 'absolute'};
  margin-left: ${props => props.circle === '3' && '415px'};
  margin-top: ${props => props.circle === '3' && '220px'};

  position: ${props => props.circle === '5' && 'absolute'};
  margin-right: ${props => props.circle === '5' && '415px'};
  margin-top: ${props => props.circle === '5' && '220px'};

  position: ${props => props.circle === '6' && 'absolute'};
  margin-right: ${props => props.circle === '6' && '415px'};
  margin-bottom: ${props => props.circle === '6' && '220px'};

  ${width1100({
    width: '14vw',
    height: '14vw'
  })}

  /* width 990 */
  ${width990({
    marginBottom: props => props.circle === '1' && '-15px'
  })}

  ${width990({
    marginLeft: props => props.circle === '2' && '345px',
    marginBottom: props => props.circle === '2' && '185px'
  })}

  ${width990({
    marginLeft: props => props.circle === '3' && '345px',
    marginTop: props => props.circle === '3' && '185px'
  })}

  ${width990({
    marginRight: props => props.circle === '5' && '345px',
    marginTop: props => props.circle === '5' && '185px'
  })}

  ${width990({
    marginBottom: props => props.circle === '4' && '15px'
  })}

  ${width990({
    marginRight: props => props.circle === '6' && '345px',
    marginBottom: props => props.circle === '6' && '185px'
  })}
`

export const CircleIcon = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(101, 216, 29, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;

  ${width1100({ width: '10vw', height: '10vw' })}

  img {
    width: ${props => props.type === 'tron' && ''};
  }
`
export const CardBoard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 150px 0px 20px 0px;
  position: relative;
  ${width768({ padding: '0', gap: '4vh' })};
`

export const CardMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`
export const LineMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`
