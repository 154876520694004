import Carousel from 'react-bootstrap/Carousel'

import bg_image from '../../assets/images/banner1.png'
import bg_image2 from '../../assets/images/banner2.png'
import bg_image3 from '../../assets/images/banner3.png'
import bg_image4 from '../../assets/images/banner4.png'
import bg_image5 from '../../assets/images/banner5.png'
import bg_image6 from '../../assets/images/banner6.png'
import { Text, Title } from './styles'

export const Carrosel = () => {
  return (
    <Carousel controls={false} style={{ height: '85vh !important' }}>
      <Carousel.Item>
        <img className="d-block w-100" src={bg_image} alt="First slide" />
        <Carousel.Caption style={{ textAlign: 'left' }}>
          <Title>Venda mais</Title>
          <Text>
            40% dos clientes que pagam com criptomoedas são clientes novos.
          </Text>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bg_image2} alt="Second slide" />

        <Carousel.Caption style={{ textAlign: 'left' }}>
          <Title>Menos despesas e mais segurança</Title>
          <Text>Menores custas e mitigação de estornos por fraude.</Text>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bg_image3} alt="Third slide" />

        <Carousel.Caption style={{ textAlign: 'left' }}>
          <Title>Plug &amp; play, sem complicação</Title>
          <Text>Receba em cripto ou em real, quando e como quiser.</Text>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bg_image4} alt="Third slide" />

        <Carousel.Caption style={{ textAlign: 'left' }}>
          <Title>Fácil e sem burocracia</Title>
          <Text>
            Com uma única integração você comercializa seus imóveis em diversas
            criptomoedas.
          </Text>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bg_image5} alt="Third slide" />

        <Carousel.Caption style={{ textAlign: 'left' }}>
          <Title>Gestão de vendas e moedas.</Title>
          <Text>
          Tenha total controle com o nosso sistema de gestão integrado.
          </Text>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bg_image6} alt="Third slide" />

        <Carousel.Caption style={{ textAlign: 'left' }}>
          <Title>Venda qualquer lugar, de qualquer lugar!</Title>
          <Text>
            Escolha seu canal de venda comece a transacionar.
          </Text>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}
